.home-link.mobile {
    margin-right: auto;
}

#menu-wrapper.mobile {
    position: fixed;
    top: 0;
    right: 0;
    width: 80%;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.9);
}

.hidden {
    display: none;
}

.menu-container.mobile {
    height: 60%;
    min-height: 250px;
    max-height: 350px;
    display: flex;
    flex-direction: column;
    font-weight: bold;
    font-size: 1rem;
}

.menu-container.mobile :first-child {
    margin-top: 7%;
}

.menu-container.mobile>.menu-item {
    margin-right: auto;
    margin-left: 7%;
}

#mobile-menu {
    position: absolute;
    right: 12%;
    z-index: 100;
}

.bar1, .bar2, .bar3 {
    width: 35px;
    height: 5px;
    background-color: #0063AB;
    margin: 6px 0;
    transition: 0.4s;
}

/* Rotate first bar */

.menu-opened .bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px);
    transform: rotate(-45deg) translate(-9px, 6px);
}

/* Fade out the second bar */

.menu-opened .bar2 {
    opacity: 0;
}

/* Rotate last bar */

.menu-opened .bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -8px);
}

.banner.mobile {
    max-width: 75%;
    padding: 12px;
    margin: auto;
}

.banner-title.mobile {
    letter-spacing: 0.1rem;
    font-size: 1.3rem;
}

.banner.mobile .text {
    font-size: small;
}

.title.mobile {
    font-size: 1.5rem;
    letter-spacing: 0.1em;
    margin-top: -45px;
}

.title.mobile:after {
    width: 150px;
}

.brands.mobile img {
    width: 100px;
}

.wrapper.mobile {
    font-size: small;
}

.wrapper.mobile .icon {
    width: 34px !important;
}

.BigLogo.mobile {
    width: 90%;
}

footer.mobile {
    font-size: small;
}

footer.mobile img {
    width: 135px;
}

.mobile-details {
    min-height: 310px;
    background-color: #0063AB;
}

.mobile-border-up {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.mobile-border-up .left {
    width: 50%;
    border-top: 25px solid #F3FAFF;
    border-right: 25px solid transparent;
}

.mobile-border-up .right {
    width: 50%;
    border-top: 25px solid #F3FAFF;
    border-left: 25px solid transparent;
}

.mobile-content {
    background-color: white;
    min-height: 320px;
    position: relative;
}

.mobile-content-border {
    height: 2px;
    background: linear-gradient(to right, rgb(0, 99, 171) 43.9%, transparent 0, transparent 56.3%, rgb(0, 99, 171) 0, rgb(0, 99, 171) 100%);
}

.mobile-content:before {
    top: -47px;
    left: 42.1%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: white;
    border-width: 25px;
}

@media(max-width: 380px) and (min-width: 340px) {
    .mobile-content-border {
        background: linear-gradient(to right, rgb(0, 99, 171) 44.9%, transparent 0, transparent 55.3%, rgb(0, 99, 171) 0, rgb(0, 99, 171) 100%);
    }
}

@media(max-width: 412px) and (min-width: 380px) {
    .mobile-content-border {
        background: linear-gradient(to right, rgb(0, 99, 171) 45.9%, transparent 0, transparent 54.3%, rgb(0, 99, 171) 0, rgb(0, 99, 171) 100%);
    }
}

@media(max-width: 542px) and (min-width: 412px) {
    .mobile-content-border {
        background: linear-gradient(to right, rgb(0, 99, 171) 46.9%, transparent 0, transparent 53.3%, rgb(0, 99, 171) 0, rgb(0, 99, 171) 100%);
    }
}

@media(max-width: 696px) and (min-width: 542px) {
    .mobile-content-border {
        background: linear-gradient(to right, rgb(0, 99, 171) 47.9%, transparent 0, transparent 52.3%, rgb(0, 99, 171) 0, rgb(0, 99, 171) 100%);
    }
}

@media(max-width: 800px) and (min-width: 696px) {
    .mobile-content-border {
        background: linear-gradient(to right, rgb(0, 99, 171) 48.9%, transparent 0, transparent 51.3%, rgb(0, 99, 171) 0, rgb(0, 99, 171) 100%);
    }
}

@media(max-width: 352px) and (min-width: 340px) {
    .mobile-content:before {
        left: 42.5%;
    }
}

@media(max-width: 363px) and (min-width: 353px) {
    .mobile-content:before {
        left: 42.7%;
    }
}

@media(max-width: 374px) and (min-width: 363px) {
    .mobile-content:before {
        left: 42.9%;
    }
}

@media(max-width: 383px) and (min-width: 375px) {
    .mobile-content:before {
        left: 43.1%;
    }
}

@media(max-width: 393px) and (min-width: 384px) {
    .mobile-content:before {
        left: 43.3%;
    }
}

@media(max-width: 409px) and (min-width: 394px) {
    .mobile-content:before {
        left: 43.5%;
    }
}

@media(max-width: 436px) and (min-width: 410px) {
    .mobile-content:before {
        left: 43.9%;
    }
}

@media(max-width: 455px) and (min-width: 437px) {
    .mobile-content:before {
        left: 44.2%;
    }
}

@media(max-width: 479px) and (min-width: 456px) {
    .mobile-content:before {
        left: 44.5%;
    }
}

@media(max-width: 505px) and (min-width: 480px) {
    .mobile-content:before {
        left: 44.8%;
    }
}

@media(max-width: 525px) and (min-width: 506px) {
    .mobile-content:before {
        left: 45%;
    }
}

@media(max-width: 555px) and (min-width: 526px) {
    .mobile-content:before {
        left: 45.3%;
    }
}

@media(max-width: 595px) and (min-width: 556px) {
    .mobile-content:before {
        left: 45.6%;
    }
}

@media(max-width: 635px) and (min-width: 596px) {
    .mobile-content:before {
        left: 45.9%;
    }
}

@media(max-width: 687px) and (min-width: 636px) {
    .mobile-content:before {
        left: 46.2%;
    }
}

@media(max-width: 732px) and (min-width: 688px) {
    .mobile-content:before {
        left: 46.5%;
    }
}

@media(max-width: 800px) and (min-width: 733px) {
    .mobile-content:before {
        left: 46.8%;
    }
}

.mobile-content-text {
    padding: 8px;
}

.mobile-content-text .promociones {
    flex-direction: column;
}

.mobile-content-text .promocion {
    max-width: 600px;
    width: 100%;
    align-self: center;
    margin-top: 6px;
}

.mobile-drop-down-button {
    margin: 3%;
}

.mobile-drop-down-button .head {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row;
    border: 1px solid grey;
    background-color: #efeff0;
}

.mobile-drop-down-button .head.active {
    color: white;
    background-color: rgb(0, 99, 171);
    border: 1px solid rgb(0, 99, 171);
}

.mobile-drop-down-button .head.active .mobile-image {
    border-right: 1px solid rgb(0, 99, 171);
    background-color: rgb(0, 99, 171);
}

.mobile-drop-down-button .mobile-image {
    width: 52px;
    padding: 3px;
    border-right: 1px solid grey;
    background-color: #e3e3e3;
}

.mobile-drop-down-button .head p {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
}

.mobile-drop-down-button .content {
    width: 90%;
    text-align: justify;
    letter-spacing: 0.01rem;
    margin-left: auto;
    margin-right: auto;
    padding: 4% 0;
}

.mobile-drop-down-button .content-animation {
    max-height: 400px;
    overflow-y: scroll;
}

.mobile-drop-down-button .content-animation::-webkit-scrollbar {
    -webkit-appearance: none;
}

.mobile-drop-down-button .content-animation::-webkit-scrollbar:vertical {
    width: 12px;
}

.mobile-drop-down-button .content-animation::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .5);
    border-radius: 10px;
    border: 2px solid #ffffff;
}

.mobile-drop-down-button .content-animation::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
}

.mobile-drop-down-button .content .content-title {
    color: rgba(0, 99, 171);
    font-weight: bold;
}

.mobile-drop-down-button .content p {
    margin: 8% 0;
}

.mobile-drop-down-button .read-more-div p {
    margin: 0;
}

.mobile-drop-down-button .read-more-div {
    padding: 0.3rem;
    margin: 1.8% 0;
}

.mobile-drop-down-button .read-more-state:not(:checked)~p {
    width: 87%;
    height: 32px;
}