/****************
******* BOX 1 ***
*****************/

.details-box-1 .arrow {
    margin-left: 6.4%;
}

.details-box-1.content-box:after {
    margin-left: -2.9%;
}

.details-box-1 .border-up-left {
    width: 13%;
}

.details-box-1 .border-up-right {
    width: 94%;
}

/****************
******* BOX 2 ***
*****************/

.details-box-2 .border-up-left {
    width: 35%;
}

.details-box-2 .border-up-right {
    width: 61%;
}

.details-box-2 .arrow {
    margin-left: 31.4%;
}

.details-box-2.content-box:after {
    margin-left: 22.1%;
}

/****************
******* BOX 3 ***
*****************/

.details-box-3 .border-up-left {
    width: 59%;
}

.details-box-3 .border-up-right {
    width: 38%;
}

.details-box-3 .arrow {
    margin-left: 56.2%;
}

.details-box-3.content-box:after {
    margin-left: 46.9%;
}

/****************
******* BOX 4 ***
*****************/

.details-box-4 .border-up-left {
    width: 83%;
}

.details-box-4 .border-up-right {
    width: 13%;
}

.details-box-4 .arrow {
    margin-left: 82.4%;
}

.details-box-4.content-box:after {
    margin-left: 73.1%;
}

/****************
******* MEDIA ***
*****************/

@media(min-width: 900px) {
    .details-box-1.content-box:after {
        margin-left: -2.8% !important;
    }
    .details-box-2.content-box:after {
        margin-left: 22.2% !important;
    }
    .details-box-3.content-box:after {
        margin-left: 47% !important;
    }
    .details-box-4.content-box:after {
        margin-left: 73.2% !important;
    }
}

@media(min-width: 1020px) {
    .details-box-1 .top-border {
        background: linear-gradient(to right, rgb(0, 99, 171) 8.9%, transparent 0, transparent 14.3%, rgb(0, 99, 171) 0, rgb(0, 99, 171) 100%) !important;
    }
    .details-box-2 .top-border {
        background: linear-gradient(to right, rgb(0, 99, 171) 33.7%, transparent 0, transparent 39.4%, rgb(0, 99, 171) 0, rgb(0, 99, 171) 100%);
    }
    .details-box-3 .top-border {
        background: linear-gradient(to right, rgb(0, 99, 171) 58.6%, transparent 0, transparent 64.1%, rgb(0, 99, 171) 0, rgb(0, 99, 171) 100%);
    }
    .details-box-4 .top-border {
        background: linear-gradient(to right, rgb(0, 99, 171) 84.7%, transparent 0, transparent 90.1%, rgb(0, 99, 171) 0, rgb(0, 99, 171) 100%);
    }
}

@media(min-width: 960px) and (max-width: 1020px) {
    .details-box-1 .top-border {
        background: linear-gradient(to right, rgb(0, 99, 171) 8.9%, transparent 0, transparent 14.5%, rgb(0, 99, 171) 0, rgb(0, 99, 171) 100%) !important;
    }
}

@media(min-width: 900px) and (max-width: 960px) {
    .details-box-1 .top-border {
        background: linear-gradient(to right, rgb(0, 99, 171) 8.8%, transparent 0, transparent 15%, rgb(0, 99, 171) 0, rgb(0, 99, 171) 100%) !important;
    }
}

@media(min-width: 830px) and (max-width: 900px) {
    .details-box-1 .top-border {
        background: linear-gradient(to right, rgb(0, 99, 171) 8.8%, transparent 0, transparent 15.5%, rgb(0, 99, 171) 0, rgb(0, 99, 171) 100%) !important;
    }
}

@media(min-width: 934px) and (max-width: 1020px) {
    .details-box-2 .top-border {
        background: linear-gradient(to right, rgb(0, 99, 171) 33.7%, transparent 0, transparent 39.7%, rgb(0, 99, 171) 0, rgb(0, 99, 171) 100%);
    }
    .details-box-3 .top-border {
        background: linear-gradient(to right, rgb(0, 99, 171) 58.5%, transparent 0, transparent 64.5%, rgb(0, 99, 171) 0, rgb(0, 99, 171) 100%);
    }
    .details-box-4 .top-border {
        background: linear-gradient(to right, rgb(0, 99, 171) 84.7%, transparent 0, transparent 90.6%, rgb(0, 99, 171) 0, rgb(0, 99, 171) 100%);
    }
}

@media(min-width: 852px) and (max-width: 934px) {
    .details-box-2 .top-border {
        background: linear-gradient(to right, rgb(0, 99, 171) 33.6%, transparent 0, transparent 40.1%, rgb(0, 99, 171) 0, rgb(0, 99, 171) 100%);
    }
    .details-box-3 .top-border {
        background: linear-gradient(to right, rgb(0, 99, 171) 58.4%, transparent 0, transparent 64.75%, rgb(0, 99, 171) 0, rgb(0, 99, 171) 100%);
    }
    .details-box-4 .top-border {
        background: linear-gradient(to right, rgb(0, 99, 171) 84.7%, transparent 0, transparent 91.1%, rgb(0, 99, 171) 0, rgb(0, 99, 171) 100%);
    }
}

@media(min-width: 800px) and (max-width: 852px) {
    .details-box-2 .top-border {
        background: linear-gradient(to right, rgb(0, 99, 171) 33.5%, transparent 0, transparent 40.6%, rgb(0, 99, 171) 0, rgb(0, 99, 171) 100%);
    }
    .details-box-3 .top-border {
        background: linear-gradient(to right, rgb(0, 99, 171) 58.4%, transparent 0, transparent 65.3%, rgb(0, 99, 171) 0, rgb(0, 99, 171) 100%);
    }
    .details-box-4 .top-border {
        background: linear-gradient(to right, rgb(0, 99, 171) 84.7%, transparent 0, transparent 91.5%, rgb(0, 99, 171) 0, rgb(0, 99, 171) 100%);
    }
}

@media(min-width: 800px) and (max-width: 830px) {
    .details-box-1 .top-border {
        background: linear-gradient(to right, rgb(0, 99, 171) 8.8%, transparent 0, transparent 15.9%, rgb(0, 99, 171) 0, rgb(0, 99, 171) 100%);
    }
}

/****************
******* BORDER **
*****************/

@media(min-width: 862px) and (max-width: 1020px) {
    .details-box-2 .border-up-right {
        width: 60%;
    }
    .details-box-3 .border-up-right {
        width: 37.5%;
    }
    .details-box-4 .border-up-right {
        width: 12.5%;
    }
}

@media(min-width: 800px) and (max-width: 862px) {
    .details-box-2 .border-up-right {
        width: 59%;
    }
    .details-box-3 .border-up-right {
        width: 36.6%;
    }
    .details-box-4 .border-up-right {
        width: 11.8%;
    }
}