.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.mapouter {
  position: relative;
  text-align: right;
  height: 500px;
  width: 100%;
}

body {
  min-width: 320px;
  font-family: 'Montserrat' !important;
}

.gmap_canvas {
  overflow: hidden;
  background: none!important;
  height: 500px;
  width: 100%;
}

footer img {
  width: 180px;
  margin-left: 36px;
}

footer a {
  color: white;
}

.home-link img {
  width: 150px;
}

#menu-wrapper {
  width: 70%;
  margin-left: 10%;
}

.menu-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  justify-content: space-evenly;
  font-weight: bold;
  font-size: 1rem;
}

@media(min-width: 850px) and (max-width: 1000px) {
  .home-link img {
    width: 125px;
  }
  .menu-container {
    font-size: 0.85rem;
  }
}

@media(min-width: 800px) and (max-width: 850px) {
  .home-link img {
    width: 100px;
  }
  .menu-container {
    font-size: 0.75rem;
  }
}

.menu-item {
  text-decoration: none;
}

.menu-item:hover {
  cursor: pointer;
}

.oceans-header {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.banner {
  color: white;
  max-width: 600px;
  background-color: rgba(0, 99, 171, 0.5);
  padding: 16px;
  text-align: justify;
}

.banner-title {
  font-weight: bold;
  width: fit-content;
  margin: 0;
  letter-spacing: 0.12rem;
  font-size: 2.3rem;
}

.banner .text {
  font-style: italic;
  text-shadow: 1px 1px black;
}

.wrapper {
  min-width: 320px;
  max-width: 1024px;
  margin: 0 auto;
  padding-bottom: 52px;
}

.brands {
  background-color: #F3FAFF;
}

.brand-img-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 52px;
}

.brands img {
  width: 150px;
  margin: 6px;
  opacity: 0.4;
}

.title {
  font-size: 3rem;
  letter-spacing: 0.5rem;
  width: fit-content;
  margin: 0 auto;
  margin-top: -18px;
  position: relative;
}

.title:after {
  /* This is necessary for the pseudo element to work. */
  content: "";
  /* This will put the pseudo element on its own line. */
  display: block;
  /* This will center the border. */
  margin: 0 auto;
  width: 270px;
  padding: 0;
  color: #0063ab;
  border-bottom-style: solid;
  border-bottom-width: 3px;
}

.react-parallax {
  height: 700px;
}

.home-link:hover {
  cursor: pointer;
}

.promociones {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow: auto;
}

.promocion {
  height: 100%;
}

.content-box {
  background-color: rgba(255, 255, 255, 0.9);
  position: absolute;
  top: 30px;
  margin: 3%;
  padding: 3%;
  width: 87%;
  height: 76%;
  border: 2px solid rgb(0, 99, 171);
  border-top: 0;
}

.hover:hover {
  cursor: pointer;
  position: relative;
  top: -15px;
}

.arrow {
  position: absolute;
  width: 37px;
  height: 38px;
  border-left: 2px solid rgb(0, 99, 171);
  border-top: 2px solid rgb(0, 99, 171);
  transform: rotate(45deg);
  top: -19px;
}

.top-border {
  position: absolute;
  width: calc(100% + 2px);
  height: 2px;
  left: 0;
  top: 0;
}

.content-box:after {
  bottom: 100%;
  left: 12%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: rgba(255, 255, 255, 0.9);
  border-width: 24px;
}

.border-up {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.border-up-left {
  width: auto;
  border-top: 25px solid #F3FAFF;
  border-right: 25px solid transparent;
}

.border-up-right {
  border-top: 25px solid #F3FAFF;
  border-left: 25px solid transparent;
}

.tabs {
  height: 100%;
  overflow-y: auto;
}

.tab-header {
  padding: 0;
  user-select: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-bottom: 3px solid rgba(0, 99, 171);
}

.tab-header li .image1 {
  width: 42px;
}

.tab-header li .image2 {
  width: 0;
}

.tab-header li {
  padding: 1%;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-basis: 20%;
  flex-grow: 1;
  border: 2px solid transparent;
  border-bottom: 0 !important;
}

.tab-header li p {
  margin: 0;
  padding: 0;
  width: 85%;
}

.tab-header .activeTab {
  color: white;
  background-color: rgba(0, 99, 171);
}

.tab-header li:hover:not(.activeTab) {
  cursor: pointer;
  color: rgba(0, 99, 171);
  border-color: rgba(0, 99, 171);
}

.tab-header li:hover:not(.activeTab) .image1 {
  width: 0;
}

.tab-header li:hover:not(.activeTab) .image2 {
  width: 42px;
}

.tab-content {
  text-align: justify;
  letter-spacing: 0.01rem;
  padding-right: 1%;
}

.weddings {
  height: 100%;
  overflow-y: auto;
}

.tab-content .content-title {
  color: rgba(0, 99, 171);
  font-weight: bold;
}

.tab-content p {
  margin: 2.1% 0;
}

.tab-content .read-more-div p {
  margin: 0;
}

.read-more-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  border: 2px solid #e6dede;
  padding: 0.5rem;
  margin: 1.9% 0;
}

.read-more-state {
  display: none;
}

.read-more {
  opacity: 0;
  height: 0;
  font-size: 0;
  transition: 0.20s ease;
}

.read-more-state:not(:checked)~p {
  height: 32px;
}

.read-more-state:checked~p .read-more {
  font-size: inherit;
  opacity: 1;
}

.read-more-trigger {
  cursor: pointer;
  color: rgba(0, 99, 171);
  margin-left: auto;
  width: 26px;
  min-width: 26px;
  text-align: right;
  font-size: 1.5em;
  font-weight: bold;
}

.read-more-state~.read-more-trigger:before {
  content: '+';
}

.read-more-state:checked~.read-more-trigger:before {
  content: '-';
}

.beauty-parties {
  text-align: justify;
  height: 100%;
  overflow-y: auto;
}

.beauty-parties .header {
  width: 98%;
  padding: 1%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.beauty-parties .header p {
  width: 90%;
  color: rgba(0, 99, 171);
  font-weight: bold;
  text-align: center;
  margin: 0;
  margin-bottom: 1%;
}

.beauty-parties .header span {
  font-size: 80%;
}

.packs {
  height: 76%;
  margin-top: 2%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
}

.pack span {
  color: rgba(0, 99, 171);
  font-weight: bold;
  text-decoration: underline;
}

.pack {
  min-width: 300px;
  max-width: 350px;
  margin: 0;
  width: 100%;
}

.cookies-bar {
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  border: 2px solid rgba(0, 99, 171);
  max-width: 100%;
  min-width: 300px;
  width: calc(100% - 4px);
  width: -webkit-fill-available;
  width: -moz-available;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  font-size: small;
}

.cookies-bar>* {
  margin: 1%;
}

.cookies-bar p {
  width: 82%;
}

.accept-cookies {
  padding: 3px 6px;
  background-color: rgba(0, 99, 171);
  border: 2px solid rgba(0, 99, 171);
  color: white;
  border-radius: 5px;
  width: 100px;
}

.accept-cookies:hover {
  cursor: pointer;
}

.web-link {
  width: fit-content;
  padding: 12px;
  text-decoration: none;
}

.web-link:not(.phone):hover {
  position: relative;
  top: -10px;
}

@media(max-width: 470px) {
  .web-link {
    width: 112px;
    height: 70px;
  }
}

@media(max-width: 1756px) {
  .react-parallax {
    height: 650px;
  }
}

@media(max-width: 1586px) {
  .react-parallax {
    height: 600px;
  }
}

@media(max-width: 1476px) {
  .react-parallax {
    height: 550px;
  }
}

@media(max-width: 1356px) {
  .react-parallax {
    height: 500px;
  }
}

@media(max-width: 1236px) {
  .react-parallax {
    height: 450px;
  }
}

@media(max-width: 1186px) {
  .react-parallax {
    height: 434px;
  }
}

@media(max-width: 1066px) {
  .react-parallax {
    height: 420px;
  }
}

.texto-legal {
  background-color: rgba(0, 99, 171);
  color: white;
  padding: 6px;
  width: calc(100% - 12px);
}

.texto-legal p {
  margin: 0;
  font-size: small;
  font-size: 0.75rem;
}